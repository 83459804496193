<template>
  <v-dialog persistent max-width="500" :value="isShown" @input="closeDialog()">
    <v-card>
      <v-card-title class="pa-4">
        <close-button right absolute @click="closeDialog()" />
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center justify-center">
        <v-icon size="68">
          $google-authentication
        </v-icon>
        <div class="my-2">
          Google authentication
        </div>
        <v-otp-input
          ref="otpInput"
          :value="googleCode"
          class="max-width-300"
          length="6"
          :disabled="loading"
          :error-messages="errors.code"
          @input="setGoogleCode($event)"
          @keydown.enter="$emit('resend-code')"
          @finish="$emit('resend-code')"
        />
        <errors v-if="errors.code" :error-messages="errors.code" class="text-center" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import CloseButton from '@/components/CloseButton.vue'

  export default {
    name: 'LoginGoogleAuthentication',
    components: { Errors, CloseButton },
    props: {
      isShown: {
        type: Boolean,
        require: true
      }
    },
    computed: {
      ...mapState('login', ['googleCode', 'loading', 'errors'])
    },
    mounted() {
      this.$refs.otpInput.focus()
    },
    methods: {
      ...mapActions('login', ['setGoogleCode', 'setAuthenticationMethod']),
      closeDialog() {
        this.setAuthenticationMethod('')
        this.$emit('close-dialog')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .max-width-300 {
    max-width: 300px;
  }
</style>
