<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      icon
      elevation="0"
      v-bind="$attrs"
      x-small
      color="white"
      :class="[hover ? 'black' : 'black opacity-20']"
      @click="$emit('click')"
    >
      <v-icon size="14">
        $close
      </v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
  export default {
    name: 'CloseButton'
  }
</script>
